@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  @apply box-border m-0 p-0 font-[Tahoma];
}

@layer components {
  .Section {
    @apply py-20 bg-white dark:bg-Secondary;
  }

  .Heading {
    @apply text-5xl font-semibold text-TextLight dark:text-white mb-6;
  }

  /* .Button {
    @apply no-underline rounded bg-[#0467fb] whitespace-nowrap py-1 px-1 text-white text-xl outline-none border-none cursor-pointer hover:transition-all hover:bg-[#4b59f7];
  } */

  .Link {
    @apply text-white md:flex items-center no-underline md:px-4 md:py-2 h-full hover:text-[#4b59f7] text-center p-8 w-full table;
  }

  .Container {
    z-index: 1;
    width: 100%;
    max-width: 1300px;
    margin-right: auto;
    margin-left: auto;
    padding-right: 50px;
    padding-left: 50px;

    @media screen and (max-width: 1000px) {
      padding-right: 30px;
      padding-left: 30px;
    }
  }

  .Column {
    margin-bottom: 15px;
    padding-right: 15px;
    padding-left: 15px;
    flex: 1;
    max-width: 50%;
    flex-basis: 50%;

    @media screen and (max-width: 760px) {
      max-width: 100%;
      flex-basis: 100%;
    }
  }

  .TextWrapper {
    max-width: 540px;
    padding-top: 0;
    padding-bottom: 60px;

    @media screen and (max-width: 760px) {
      padding-bottom: 65px;
    }
  }

  .Description {
    max-width: 440px;
    margin-bottom: 35px;
    font-size: 18px;
    line-height: 24px;
    /* color: ${({ LightTH }) => (LightTH ? "#707070" : "#fff")}; */
  }

  .Button {
    text-decoration: none;
    border-radius: 4px;
    background: #0467fb;
    white-space: nowrap;
    padding: 12px 64px;
    color: #fff;
    font-size: 20px;
    outline: none;
    border: none;
    cursor: pointer;

    &:hover {
      transition: all 0.3s ease-out;
      background: #fff;
      background-color: #4b59f7;
    }

    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }

  .MobileIcon {
    display: none;

    @media screen and (max-width: 960px) {
      display: block;
      position: absolute;
      top: 0;
      right: 0;
      transform: translate(-100%, 60%);
      font-size: 1.8rem;
      cursor: pointer;
    }
  }

  .NavMenu {
    display: flex;
    align-items: center;
    list-style: none;
    text-align: center;

    @media screen and (max-width: 960px) {
      display: flex;
      flex-direction: column;
      width: 100%;
      height: 90vh;
      position: absolute;
      top: 80px;
      /* left: ${({ click }) => (click ? 0 : "-100%")}; */
      opacity: 1;
      transition: all 0.5s ease;
      background: #101522;
    }
  }

  .NavItem {
    height: 80px;
    cursor: pointer;

    @media screen and (max-width: 960px) {
      width: 100%;
    }
  }
}
